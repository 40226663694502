/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  getOrientation,
  resetOrientation,
  checkImage,
  fetchApi
} from "../../utils/methods";
import { ImageUrl, imageErrorMessage } from "../../utils/constants";
import { withTranslation } from "react-i18next";
import { Main } from "../AdminTeamCreation/styles";
import StepsHeading from "../CreateEvent/stepNames";
import Waiting from "../Waiting";
import HoverButton from "../common/HoverButton";
import {
  Arrows,
  LaunchEventIcon,
  NextArrows,
  UploadIcon,
  closeIcon,
  CalendarIcon,
  CalendarIconDisabled
} from "../../utils/icons";
import { Border } from "../Journeys/styles";
import { withRouter } from "react-router-dom";
import RadioButton from "../CustomRadioButton";
import CommonButton from "../common/CommonButton/CommonButton";
import {
  ChallengeHeading,
  ButtonContainer,
  Layout,
  Container,
  InputContainer,
  StyledLabel,
  StyledInputV2,
  UploadPhotoContainer,
  RemoveImageV2,
  InputImage,
  EditorContainer,
  Heading,
  Locationwrapper,
  CustomRadioButton,
  StyledLabelV2,
  MUICalendarWrapper
} from '../CreateEvent/style';
import Editor from "../CKEditor";
import { getCompanyDepartment, getCompanyLocation} from "../../redux/actions";
import { GetCompanyInfo, GetTeams } from "../../redux/constants/apiConstants";
import { http } from "../../redux/http";
import {
  getUserCompanyDetails,
  allowChallengeEventForSpouseDependent,
} from "../../redux/actions/userProfileActions";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import CommonDropdown from "../common/CommonDropDown/CommonDropdown";
import { eventsTierPoints, surveyWholeQuizTime, surveyPerQuestionTime } from "../../../mockData";
import { AddWellnessQuiz } from "../../redux/constants/apiConstants";

class LaunchQuiz extends Component {
  constructor(props) {
    super(props);
    this.targetDivRef = React.createRef();
    this.state = {
      imgSrc: ImageUrl+"/"+props?.history?.location?.state?.image,
      title: "",
      description: "",
      imageName: "",
      step: 1,
      buttonClickScrollTop: false,
      loading: false,
      imageUpdate: 0,
      companyId: props.companyInfo["id"],
      createdSurveyDetails: {},
      openDateTimePicker: "",
      date: dayjs(),
      // date:null,
      showDateTimeInput: "",
      disable: true,
      usersToInvite:0,
      allTeams:[],
      companyInfo:null,
      city_location: [],
      departments:[],
      teams:[],
      launch:0,
      isDependent:false,
      isSpouse:false,
      isEmployee: false,
      ids:props?.history?.location?.state?.ids,
      selectedOption: null,
      quizTimeValue: null,
      quizTimeText: null,
      emailOption: {
        quiz_creation_email: 0,
        quiz_reminder_email: 0,
        quiz_complete_email: 0,
      },
      buttonStatus: false,
      quizPoint: null
    };
  }
  _isMounted = false;


  componentDidMount() {
    // window.console.log("this.props.companyDetails.id",this.props.companyDetails.id)
    // window.console.log("company id",this.state.companyId)
    

    const {getCompanyDepartment, getCompanyLocation, allowChallengeEventForSpouseDependent, fetchCompanyForUser}=this.props;
    // getCompanyDepartment(this.props.companyDetails.id);
    // getCompanyLocation(this.props.companyDetails.id);
    getCompanyDepartment(this.state.companyId);
    getCompanyLocation(this.state.companyId);
    this.getTeams(this.state.companyId);
    this.getCompanyInfo(this.state.companyId);
    allowChallengeEventForSpouseDependent();
    fetchCompanyForUser();
  }

  getTeams = async(companyId)=>{
    const AuthToken = localStorage.getItem('AUTH_TOKEN');
    try {
      const res = await http.get(`${GetTeams}/${companyId}`, {headers: {AUTHTOKEN: AuthToken}});
      if (res.message) {
        toast.error(res.message);
      }else{
        this.setState({
          allTeams:res
        })
      }
    }catch(error){
      toast.error(error.message);
    }
  }

  getCompanyInfo = async(companyId)=>{
    let apiUrl = GetCompanyInfo + "/" + companyId;
    try {
      const res = await fetchApi(apiUrl , "GET");
      if (res.status === "success") {
        this.setState({companyInfo: res?.data?.companyInfo?.[0]})

      } else {
        toast.error(res.error);
      }
    } catch (error) {
      window.console.log(error);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClick);
  }

  componentDidUpdate() {
    if (this.state.buttonClickScrollTop) {
      window.scrollTo(0, 0);
      window.setTimeout(() => {
        this.setState({ buttonClickScrollTop: false });
      }, 2000);
    }
  }

  handleClick = (e) => {
    if (
      typeof this.datePickerWrapper === "undefined" &&
      this.datePickerWrapper === null
    ) {
      return;
    }
    if (
      !_.isNull(this.datePickerWrapper) &&
      !_.isUndefined(this.datePickerWrapper) &&
      this.datePickerWrapper &&
      !this.datePickerWrapper.contains(e.target)
    ) {
      this.setState({
        openDateTimePicker: "",
      });
    }
  };

 


  checkBase64(image) {
    if (image) {
      return image.includes("base64");
    }
    return false;
  }



  onChange = (e) => {
    let file = e.target.files[0];
    let fileArr = e.target.files;
    if (e.target.files[0]) {
      if (checkImage(fileArr)) {
        if (file?.size / 1000000 <= 20) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          const array = document
            .getElementById("event-upload-file")
            .value.split("\\");
          reader.onloadend = () => {
            this.setState({
              imgSrc: reader.result,
              imageName: array[array.length - 1],
            });
            getOrientation(file, (or) => {
              resetOrientation([reader.result], or, (baseImage) => {
                this.setState({
                  imgSrc: baseImage,
                  imageUpdate: 1,
                });
              });
            });
          };
        } else {
          toast.error("Please select image file less than 20MB");
          document.getElementById("event-upload-file").value = "";
        }
      } else {
        toast.error(imageErrorMessage);
        document.getElementById("event-upload-file").value = "";
      }
    }
  };

  onChangeInput = (e) => {
    if (e.target.value.charAt(0) !== " ") {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  removePhoto = () => {
    this.setState({
      imgSrc: "",
      imageName: "",
    });
  };

  onChangeDescription = (evt) => {
    const newContent = evt.getData();
    const trimmedString = newContent.replace(/\s|&nbsp;/g, "");
    trimmedString.length === 0 ||
    trimmedString.length === 7 ||
    trimmedString == "<p></p><p></p>"
      ? this.setState({ description: "" })
      : this.setState({ description: newContent });
  };

  updateStep = (value) => {
    this.setState({ step: value, buttonClickScrollTop: true });
  };

  close = () => (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_14978_75731"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <rect width={24} height={24} fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_14978_75731)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3605 0.678084C22.6358 -0.0466026 21.4608 -0.0466023 20.7361 0.678084L11.9067 9.50756L3.07795 0.678849C2.4145 0.0154025 1.33884 0.0154032 0.675399 0.678849C0.0119522 1.3423 0.0119521 2.41796 0.675399 3.0814L9.50411 11.9101L0.675642 20.7386C-0.0490441 21.4633 -0.0490441 22.6382 0.675643 23.3629C1.40033 24.0876 2.57528 24.0876 3.29996 23.3629L12.1284 14.5344L20.9577 23.3637C21.6211 24.0271 22.6968 24.0271 23.3602 23.3637C24.0237 22.7002 24.0237 21.6246 23.3602 20.9611L14.531 12.1319L23.3605 3.30241C24.0851 2.57772 24.0851 1.40277 23.3605 0.678084Z"
          fill="#9C9C9C"
        />
      </g>
    </svg>
  );

  renderHeading = () => (
    <ChallengeHeading color={"#005c87"}>
      <div style={{marginRight:"12px"}}>
        <HoverButton
          title={this.props.t("Close")}
          width="24px"
          height="24px"
          svgPath={this.close()}
          onClick={() => this.props.history.goBack()}
          activeColor={"#007AB1"}
          fillOpacity={1}
        />
      </div>
      {this.props.t("Launch the quiz")}
    </ChallengeHeading>
  );

  stepOneFormData = () => {
    const {
      title,
      description,
      step,
    } = this.state;

    // window.console.log("imgsrce inside the form",this.state.imgSrc)
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container color="#005C87">
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} type="quiz" />
            </div>
            <div className="form">
              <div className="heading">
                <div className="step" >{step}</div>
                <div className="headingName" style={{color:"#005c87"}}>{this.props.t("Quiz Introduction")}</div>
              </div>
              <div className="formBody">
                <InputContainer>
                  <StyledLabel color={"#005c87"}>{this.props.t("Title of the quiz")}{<span>*</span>}</StyledLabel>
                  <StyledInputV2
                    placeholder={"Write title here..."}
                    name="title"
                    onChange={this.onChangeInput}
                    value={title}
                    maxLength="100"
                    color="#005c87"
                    border={"1px solid #afcbd3"}
                  />
                </InputContainer>
                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px'}}/>
                <StyledLabel style={{marginTop:"0px"}} color={"#005c87"}>{this.props.t("Quiz image")}{<span>*</span>}</StyledLabel>
                <UploadPhotoContainer>
                  <div className="innerDivImageContainer">
                    {this.state.imgSrc ? (
                      <RemoveImageV2 >
                        <img alt="profilre-img" src={this.state.imgSrc}></img>
                        <div className="main-div" onClick={this.removePhoto}>
                          <div className="icon-div" ><div className="sub-icon-div">{closeIcon("white","8","8")}</div></div>
                        </div>
                      </RemoveImageV2>
                    ) : (
                      <div className="uploadImage">
                        <InputImage
                          className="edit-profile-pic "
                          imageReload={1}
                          style={{background:"none"}}
                        >
                          <div className="middle-section">
                            <div>{UploadIcon()}</div>
                            <div className="title">{this.props.t("Upload File")}</div>
                            <div className="sub-title">{this.props.t(".jpeg, .png")}</div>
                            
                          </div>
                          <input
                            id="event-upload-file"
                            type="file"
                            name="user"
                            accept=".jpeg, .png, .jpg"
                            multiple={false}
                            onChange={(e) => this.onChange(e)}
                            onClick={(e) =>
                              e.target.files[0] && this.onChange(e)
                            }
                          />
                        </InputImage>
                      </div>
                    )}
                  </div>
                </UploadPhotoContainer>
                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px'}}/>
                <InputContainer>
                  <StyledLabel style={{marginTop:"0px"}} color={"#005c87"}>{this.props.t("About the quiz")}{<span>*</span>}</StyledLabel>
                  <EditorContainer>
                    <Editor
                      content={description}
                      onChange={this.onChangeDescription}
                    />
                  </EditorContainer>
                </InputContainer>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  onSelectPoints = (name, /*value, key*/) => {
    this.setState({
      quizPoint: name.value
    });
  };

  onSelectTime = (name, /*value, key*/) => {
    this.setState({
      quizTimeValue: name.value,
      quizTimeText: name.key
    });
  };

  onSelectCategory = (name, /*value, key*/) => {
    this.setState({
      selectedSurveyCategory: name.name,
      selectedSurveyValue: name.id
    });
  };

  toggleRadioButton = (e) => {
    this.setState({ selectedOption: e, quizTimeText:null, quizTimeValue: null });
  };

  stepTwoForm = () => {
    const {
      step,
      quizPoint,
      selectedOption,
      quizTimeValue,
      quizTimeText
    } = this.state;
    const {t}= this.props;
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container color={"#005C87"}>
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} type="quiz" />
            </div>
            <div className="form">
              <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName">
                  {this.props.t("How would you like to customize your quiz?")}
                </div>
              </div>
              <div className="formBody">
                <div>
                  <StyledLabel color={"#005c87"}>{this.props.t("Set a custom time limit for the quiz")}{<span>*</span>}</StyledLabel>
                  <div className="radioButtonDiv">
                    <span onClick={() => this.toggleRadioButton(0)}>
                      <RadioButton
                        id="whole"
                        handler={this.toggleRadioButton}
                        value={0}
                        isChecked={selectedOption === 0}
                        label={t("Whole Quiz")}
                        challengeCreate={true}
                      />
                    </span>
                    <span onClick={() => this.toggleRadioButton(1)}>
                      <RadioButton
                        id="per"
                        handler={this.toggleRadioButton}
                        value={1}
                        isChecked={selectedOption === 1}
                        label={t("Per Question")}
                        challengeCreate={true}
                        style={{color:"#005c87"}}
                      />
                    </span>
                  </div>
                  {selectedOption!=null&&
                  <CommonDropdown
                    tileContainerStyle={{ width: "100%",padding:"0px 0px 20px 0px" }}
                    dropdownStyle={{ top: "90px" }}
                    labelText={this.props.t("Select time limit")}
                    isRequired={true}
                    dropdownOpen={this.state.so}
                    placeholder={this.props.t("Select time limit")}
                    title={
                      quizTimeText
                    }
                    id="dropdown-recurring"
                    onClick={() => this.setState({ so: !this.state.so })}
                    data={selectedOption===0?surveyWholeQuizTime:surveyPerQuestionTime}
                    onSelect={this.onSelectTime}
                    active={quizTimeValue}
                    onSelectParameter={["quizTimeValue","key", "value"]}
                    itemValue={true}
                    valueIndex={1}
                    labelMargin={"0px"}
                  />}
                  <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px', display:"flex"}}/>
                  <CommonDropdown
                    tileContainerStyle={{ width: "100%",padding:"0px 0px 20px 0px" }}
                    dropdownStyle={{ top: "90px" }}
                    labelText={this.props.t("Quiz point value")}
                    isRequired={true}
                    dropdownOpen={this.state.so}
                    placeholder={this.props.t("Select point value")}
                    title={
                      quizPoint
                    }
                    id="dropdown-recurring"
                    onClick={() => this.setState({ so: !this.state.so })}
                    data={eventsTierPoints}
                    onSelect={this.onSelectPoints}
                    active={quizPoint}
                    onSelectParameter={["quizPoint", "value", "key"]}
                    valueString={"Points"}
                    itemValue={true}
                    valueIndex={1}
                    labelMargin={"0px"}
                  />
                </div>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  showDatePicker = (value) => {
    if (this.state.openDateTimePicker === value) {
      this.setState({
        openDateTimePicker: "",
      });
    } else {
      this.setState({
        openDateTimePicker: value,
      });
    }
  };

  setDatePickerWrapper = (node) => {
    this.datePickerWrapper = node;
  };

  removeCommaFromFirstPlace = (str) => {
    if (str.charAt(0) === ",") {
      return str.substring(1);
    }
    return str;
  };

  inviteToggleButton = (e) => {
    window.console.log("here is the e for toggle",e)
    this.setState({
      usersToInvite: e,
    });
  };

  onChangeLocation = (id) => {
    const { city_location } = this.state;
    if (city_location.includes(id)) {
      let index = city_location.findIndex((item) => item === id);
      if (index > -1) {
        city_location.splice(index, 1);
      }
    } else {
      city_location.push(id);
    }
    this.setState({
      city_location: city_location,
    });
  };

  onChangeDepartment = (id) => {
    const { departments } = this.state;
    if (departments.includes(id)) {
      let index = departments.findIndex((item) => item === id);
      if (index > -1) {
        departments.splice(index, 1);
      }
    } else {
      departments.push(id);
    }
    this.setState({
      departments: departments,
    });
  };

  onChangeTeam = (id) => {
    const { teams } = this.state;
    if (teams.includes(id)) {
      let index = teams.findIndex((item) => item === id);
      if (index > -1) {
        teams.splice(index, 1);
      }
    } else {
      teams.push(id);
    }
    this.setState({
      teams: teams,
    });
  };

  selectAllDepartment = (e, locationDetails) => {
    e.preventDefault();
    const { departments } = this.state;
    if (departments.length == locationDetails?.length) {
      this.setState({
        departments: [],
      });
    } else {
      let arr = [];
      for (let i = 0; i < locationDetails?.length; i++) {
        arr.push(locationDetails[i].id);
      }
      this.setState({
        departments: arr,
      });
    }
  };

  selectAllTeam = (e, teamDetails)=>{
    e.preventDefault();
    const { teams } = this.state;
    if (teams.length == teamDetails.length) {
      this.setState({
        teams: [],
      });
    } else {
      let arr = [];
      for (let i = 0; i < teamDetails.length; i++) {
        arr.push(teamDetails[i].id);
      }
      this.setState({
        teams: arr,
      });
    }
  }

  selectAllLocation = (e, locationDetails) => {
    e.preventDefault();
    const { city_location } = this.state;
    if (city_location.length == locationDetails?.length) {
      this.setState({
        city_location: [],
      });
    } else {
      let arr = [];
      for (let i = 0; i < locationDetails?.length; i++) {
        arr.push(locationDetails[i].id);
      }
      this.setState({
        city_location: arr,
      });
    }
  };

  selectSpouseDependent = (stateName) => {
    this.setState((prev) => ({
      [stateName]: !prev[stateName],
    }));
  };

  stepThreeFormData = () => {
    const {
      city_location,
      step,
      isEmployee,
      isDependent,
      isSpouse,
      usersToInvite,
      allTeams,
      departments,
      teams,
      companyInfo
    } = this.state;
    const { locationDetails, t, departmentDetails } = this.props;
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container color={"#005c87"}>
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} type="quiz" />
            </div>
            <div className="form">
              <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName">{this.props.t("Who would you like to invite?")}</div>
              </div>
              <div className="formBody">
                { (<><div className="name" style={{color:"#005c87",marginTop: "20px",marginBottom:"16px"}} >
                  {this.props.t("Select users")}
                  <span className="astric" style={{color:"#F4AAA9"}}>*</span>
                </div>
                <div className="radioButtonDiv" style={{zIndex:"10",position:"relative"}}>
                  <span onClick={() => this.inviteToggleButton(0)}>
                    <RadioButton
                      id="all-users"
                      handler={this.inviteToggleButton}
                      value={0}
                      isChecked={usersToInvite === 0}
                      label={t("All Users")}
                      challengeCreate={true}
                      style={{color:"#005c87"}}
                    />
                  </span>
                  {departmentDetails?.length > 0 &&(<span onClick={() => this.inviteToggleButton(1)}>
                    <RadioButton
                      id="departments"
                      handler={this.inviteToggleButton}
                      value={1}
                      isChecked={usersToInvite === 1}
                      label={t("Departments")}
                      challengeCreate={true}
                      style={{color:"#005c87"}}
                    />
                  </span>)}
                  { locationDetails?.length > 0 &&(<span onClick={() => this.inviteToggleButton(2)}>
                    <RadioButton
                      id="locations"
                      handler={this.inviteToggleButton}
                      value={2}
                      isChecked={usersToInvite === 2}
                      label={t("Locations")}
                      challengeCreate={true}
                      style={{color:"#005c87"}}
                    />
                  </span>)}
                  { allTeams.length > 0 &&( <span onClick={() => this.inviteToggleButton(3)}>
                    <RadioButton
                      id="teams"
                      handler={this.inviteToggleButton}
                      value={3}
                      isChecked={usersToInvite === 3}
                      label={t("Teams")}
                      challengeCreate={true}
                      style={{color:"#005c87"}}
                    />
                  </span>)}
                </div></>)}
                {(locationDetails &&
                  locationDetails?.length > 0 && usersToInvite === 2 ) ? (<Heading>
                    <div className="name" style={{color:"#005c87"}}>
                      {this.props.t("Select locations")}
                      <span className="astric">*</span>
                    </div>
                  </Heading>
                  ) : null}
                {(locationDetails &&locationDetails.length > 0 &&
                  usersToInvite === 2 ) ? (
                    <Locationwrapper  background="white" style={{zIndex:"10",position:"relative"}}>
                      <div className="checkBoxWidth" >
                        <span style={{color:"#005c87"}}>
                          <CustomRadioButton
                            onClick={(e) =>
                              this.selectAllLocation(e, locationDetails)
                            }
                            style={{color:"#005c87",border:"1px solid #669db7"}}
                            background={"#85c0ea"}
                          >
                            {" "}
                            {locationDetails &&
                              city_location.length === locationDetails.length && (<div></div>)}{" "}
                          </CustomRadioButton>{" "}
                          {this.props.t("All Locations")}
                        </span>
                      </div>
                      {locationDetails && locationDetails.length > 0
                        ? locationDetails.map((loc, index) => (
                          <div className="checkBoxWidth" key={index}>
                            <span style={{color:"#005c87"}}>
                              <CustomRadioButton
                                onClick={() => this.onChangeLocation(loc.id)}
                                background="#afcbd3"
                                style={{border:"1px solid #649bb3"}}
                              >
                                {" "}
                                {city_location.includes(loc.id) && (
                                  <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                                )}{" "}
                              </CustomRadioButton>
                              {this.removeCommaFromFirstPlace(loc.location)}
                            </span>
                          </div>
                        ))
                        : null}
                    </Locationwrapper>
                  ) : null}
                {/* Department Listing */}
                {(departmentDetails && departmentDetails?.length > 0 &&
                  usersToInvite === 1 ) ? (<Heading>
                    <div className="name" style={{color:"#005c87"}}>
                      {this.props.t("Select departments")}
                      <span className="astric">*</span>
                    </div>
                  </Heading>
                  ) : null}
                {(departmentDetails && departmentDetails?.length > 0 &&
                  usersToInvite === 1 ) ? (
                    <Locationwrapper  background="white" style={{zIndex:"10",position:"relative"}}>
                      <div className="checkBoxWidth" >
                        <span style={{color:"#005c87"}}>
                          <CustomRadioButton
                            onClick={(e) =>
                              this.selectAllDepartment(e, departmentDetails)
                            }
                            style={{color:"#005c87",border:"1px solid #669db7"}}
                            background={"#85c0ea"}
                          >
                            {" "}
                            {departmentDetails &&
                              departments?.length === departmentDetails?.length && (<div></div>)}{" "}
                          </CustomRadioButton>{" "}
                          {departmentDetails &&
                          departments.length === departmentDetails?.length
                            ? this.props.t("Deselect All")
                            : this.props.t("Select All")}
                        </span>
                      </div>
                      {departmentDetails && departmentDetails?.length > 0
                        ? departmentDetails.map((dep, index) => (
                          <div className="checkBoxWidth" key={index}>
                            <span style={{color:"#005c87"}}>
                              <CustomRadioButton
                                onClick={() => this.onChangeDepartment(dep.id)}
                                background="#afcbd3"
                                style={{border:"1px solid #649bb3"}}
                              >
                                {" "}
                                {departments.includes(dep.id) && (
                                  <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                                )}{" "}
                              </CustomRadioButton>
                              {this.removeCommaFromFirstPlace(dep.department)}
                            </span>
                          </div>
                        ))
                        : null}
                    </Locationwrapper>
                  ) : null}

                {/* Teams Listing */}

                {(allTeams && allTeams.length > 0 &&
                  usersToInvite === 3 ) ? (<Heading>
                    <div className="name" style={{color:"#005c87"}}>
                      {this.props.t("Select teams")}
                      <span className="astric">*</span>
                    </div>
                  </Heading>
                  ) : null}
                {(allTeams &&allTeams.length > 0 &&
                  usersToInvite === 3 ) ? (
                    <Locationwrapper  background="white" style={{zIndex:"10",position:"relative"}}>
                      <div className="checkBoxWidth" >
                        <span style={{color:"#005c87"}}>
                          <CustomRadioButton
                            onClick={(e) =>
                              this.selectAllTeam(e, allTeams)
                            }
                            style={{color:"#005c87",border:"1px solid #669db7"}}
                            background={"#85c0ea"}
                          >
                            {" "}
                            {allTeams &&
                              teams.length === allTeams.length && (<div></div>)}{" "}
                          </CustomRadioButton>{" "}
                          {allTeams &&
                          teams.length === allTeams.length
                            ? this.props.t("Deselect All")
                            : this.props.t("Select All")}
                        </span>
                      </div>
                      {allTeams && allTeams.length > 0
                        ? allTeams.map((team, index) => (
                          <div className="checkBoxWidth" key={index + team}>
                            <span style={{color:"#005c87"}} >
                              <CustomRadioButton
                                onClick={() => this.onChangeTeam(team.id)}
                                background="#afcbd3"
                                style={{border:"1px solid #649bb3"}}
                              >
                                {" "}
                                {teams.includes(team.id) && (
                                  <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                                )}{" "}
                              </CustomRadioButton>
                              {this.removeCommaFromFirstPlace(team.name)}
                            </span>
                          </div>
                        ))
                        : null}
                    </Locationwrapper>
                  ) : null}

                {(companyInfo?.show_spouse || companyInfo?.show_dependent ||  (locationDetails?.length === 0 && usersToInvite === 2))   ? ( <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px',display:"flex"}}/>) : null}

                {/* Spouse Dependent */}

                {(companyInfo?.show_spouse || companyInfo?.show_dependent ||  (locationDetails?.length === 0 && usersToInvite === 2))   ? (                 
                  <Heading>
                    <div className="name" style={{color:"#005c87"}}>{("Are you also interested in extending an invitation?")}</div>
                  </Heading>) : null}

                { <Locationwrapper  background="white" isWidth={"15%"} >
                  {companyInfo?.show_spouse ?<div className="checkBoxWidth">
                    <span style={{color:"#005c87"}}>
                      <CustomRadioButton
                        onClick={() => this.selectSpouseDependent("isSpouse")}
                        background="#afcbd3"
                        style={{border:"1px solid #649bb3"}}
                      >
                        {" "}
                        {isSpouse && (
                          // <div></div>
                          <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                        )}{" "}
                      </CustomRadioButton>
                      {this.props.t("Spouse")}
                    </span>
                  </div>:null}
                  {companyInfo?.show_dependent ?<div className="checkBoxWidth">
                    <span style={{color:"#005c87"}}>
                      <CustomRadioButton
                        onClick={() =>
                          this.selectSpouseDependent("isDependent")
                        }
                      >
                        {" "}
                        {isDependent && (
                          <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                        )}{" "}
                      </CustomRadioButton>
                      {this.props.t("Dependent")}
                    </span>
                  </div>:null}
                  {locationDetails?.length === 0 && usersToInvite === 2 &&  (
                    <div className="checkBoxWidth">
                      <span>
                        <CustomRadioButton
                          onClick={() =>
                            this.selectSpouseDependent("isEmployee")
                          }
                        >
                          {" "}
                          {isEmployee && (
                            <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                          )}{" "}
                        </CustomRadioButton>
                        {this.props.t("Employee")}
                      </span>{" "}
                    </div>
                  )}
                </Locationwrapper>}
                {/* Spouse Dependent */}
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  changeStep = (value) => {
    this.setState({ step: value });
  }

  launchToggleButton = (e) => {
    this.setState({
      launch: e,
      date: null,

      // companies: [],
      // selectedCompanyValue: "",
    });
    if (e === 0) {
      this.setState({
        date: dayjs()  
        // city_location: [],
      });
    }
  };

  onChangeDate = (e, stateName) => {
    const { showDateTimeInput } = this.state;
    if (showDateTimeInput === "date") {
      this.setState({
        date: dayjs(e),
        // date: null,
        openDateTimePicker: "",
      });
    } else if (stateName.includes("ate")) {
      this.setState({
        openDateTimePicker: "",
        [stateName]: dayjs(e),
      });
    } else {
      this.setState({
        [stateName]: e,
        openDateTimePicker: stateName,
      });
    }
  };

  onChangeEmail = (key) => {
    const { emailOption } = this.state;
    emailOption[key] = emailOption[key] === 0 ? 1 : 0;
    this.setState({ emailOption: emailOption });
  };

  stepFourForm = () => {
    const {
      step,
      showDateTimeInput,
      date,
      launch,
      emailOption
    } = this.state;

    // window.console.log("emailOption",emailOption)
    let emailOptionKeys = Object.keys(emailOption);
    // window.console.log("emailOptionKeys",emailOptionKeys)
    const { t } = this.props;
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container color={"#005c87"}>
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} type="quiz" />
            </div>
            <div className="form">
              <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName">{this.props.t("When would you like to launch your quiz?")}</div>
              </div>
              <div className="formBody">
                <StyledLabel color="#005c87">
                  {this.props.t("Launch quiz")}{<span>*</span>}
                </StyledLabel>
                <React.Fragment>
                  <div className="radioButtonDiv" style={{marginTop:"20px"}}>
                    <span onClick={() => this.launchToggleButton(0)}>
                      <RadioButton
                        id="global"
                        handler={this.launchToggleButton}
                        value={0}
                        isChecked={launch === 0}
                        label={t("Launch now")}
                        challengeCreate={true}
                        style={{color:"#005c87"}}
                      />
                    </span>
                    <span onClick={() => this.launchToggleButton(1)}>
                      <RadioButton
                        id="company"
                        handler={this.launchToggleButton}
                        value={1}
                        isChecked={launch === 1}
                        label={t("Schedule for later")}
                        challengeCreate={true}
                        style={{color:"#005c87"}}
                      />
                    </span>
                  </div>
                  <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px',display:"flex"}}/>
                </React.Fragment>
                <StyledLabelV2 justify style={{marginTop:"20px"}}>
                  <div className="name" style={{ color: "#005c87" }}>
                    {this.props.t("Quiz date")}{<span>*</span>}
                  </div>
                </StyledLabelV2>
                {showDateTimeInput === "date" && (
                  <React.Fragment>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MUICalendarWrapper>
                        <DateTimePicker
                          label="Select Start Date"
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          sx={{width:"100%",color:"#005C87",borderColor:"#005C87"}}
                          onChange={(value)=>{this.onChangeDate(value.$d, "date")}}
                          value={date}
                          disablePast={true}
                          minDate={dayjs().add(1, 'day')}
                          slots={{openPickerIcon:CalendarIcon}}
                          views={['year', 'month', 'day']}
                        />
                      </MUICalendarWrapper>
                    </LocalizationProvider>
                  </React.Fragment>
                )}
                {showDateTimeInput != "date" && (
                  <React.Fragment>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MUICalendarWrapper color={date ===null ? "#005C874D" : "#005C87 !important"}>

                        <DateTimePicker
                          label="Select Start Date"
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          sx={{width:"100%",color:"#005C87",borderColor:"#005C87"}}
                          onChange={(value)=>{this.onChangeDate(value.$d, "date")}}
                          value={date}
                          disabled={launch === 0}
                          minDate={dayjs().add(1, 'day')}
                          // slots={{openPickerIcon:CalendarIcon}}
                          slots={launch ===0 ? {openPickerIcon :CalendarIconDisabled} :{openPickerIcon:CalendarIcon}}
                          
                          disablePast={true}
                          views={['year', 'month', 'day']}
                        />
                      </MUICalendarWrapper>
                    </LocalizationProvider>
                  </React.Fragment>
                )}
                <StyledLabel color="#005c87">
                  {this.props.t("Select your email notifications for this quiz")}
                </StyledLabel>
                <Locationwrapper
                  border
                  background="none"
                  marginTop="0"
                  padding="0"
                  isWidth={"24.33%"}
                >
                  {emailOptionKeys.map((item, index) => (
                    <div className="checkBoxWidth" key={index}>
                      <span style={{color:"#005c87"}}>
                        <CustomRadioButton
                          checked={emailOption[item] === 1}
                          onClick={() => this.onChangeEmail(item)
                          }
                          style={{border:"1px solid #649bb3"}}
                        >
                          {emailOption[item] === 1 && (
                            <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                          )}
                        </CustomRadioButton>
                        {t("Quiz")}
                        {item == "quiz_creation_email"
                          ? t(" Creation")
                          : item == "quiz_reminder_email"? t(" Reminder")
                            : t(" Completion")}
                      </span>
                    </div>
                  ))}
                </Locationwrapper>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  saveQuiz = () => {
    const {
      city_location,
      title,
      date,
      description,
      imgSrc,
      quizPoint,
      locationPermission,
      usersToInvite,
      departments,
      teams,
      companyId,
      quizTimeValue,
      selectedOption,
      emailOption,
      ids
    } = this.state;
    // window.console.log("imgsrc",imgSrc)
    const { companyDetails } = this.props;
    this.setState({
      buttonStatus: true,
    });
    const imagePath = this.checkBase64(imgSrc) ? imgSrc : this.props?.history?.location?.state?.image;
    let QuizDetails = {
      title: title,
      description: description,
      image: imagePath,
      // image: imgSrc,
      // image: this.checkBase64(imgSrc)?  imgSrc : this.props?.history?.location?.state?.image ,
      start_date:dayjs(date).format("YYYY-MM-DD"),
      quiz_point: quizPoint,
      city_sates: city_location,
      quiz_for_spouse: this.state.isSpouse ? 1 : 0,
      quiz_for_dependent:this.state.isDependent ? 1 : 0,
      companies:[companyId],
      quiz_type: selectedOption==1?1:0,
      quize_time: quizTimeValue,
      ...emailOption,
      quiz_ids: ids.toString()
    };


    window.console.log("QuizDetails",QuizDetails)
    if (locationPermission) {
      QuizDetails.quiz_for_spouse = this.state.isSpouse ? 1 : 0;
      QuizDetails.quiz_for_dependent = this.state.isDependent ? 1 : 0;
      if (this.props.locationDetails && this.props.locationDetails?.length > 0) {
        if(city_location.length > 0){
          (QuizDetails.quiz_for_employee = 1)
        }
        else{
          (QuizDetails.quiz_for_employee = 0)
        }
      } else {
        if (companyDetails["spouse_or_dependent"]) {
          QuizDetails.quiz_for_employee = this.state.isEmployee ? 1 : 0;
        } else {
          QuizDetails.quiz_for_employee = 1;
        }
      }
    }
 
    if(usersToInvite === 0){
      QuizDetails.departments = null;
      QuizDetails.city_sates = null;
      QuizDetails.teams = null;
      QuizDetails.quiz_for_employee = 1;
    }else if(usersToInvite === 1){
      QuizDetails.departments = departments;
      QuizDetails.city_sates = null;
      QuizDetails.teams = null;
      QuizDetails.quiz_for_employee = 0;
    }else if(usersToInvite === 2){
      QuizDetails.departments = null;
      QuizDetails.city_sates = city_location;
      QuizDetails.teams = null;
      QuizDetails.quiz_for_employee = 0;
    }else if(usersToInvite === 3){
      QuizDetails.teams = teams;
      QuizDetails.departments = null;
      QuizDetails.city_sates = null;
      QuizDetails.quiz_for_employee = 0;
    }
    this.PostEvent(QuizDetails);
  };

  PostEvent = async(payload)=>{
    try{
      const res = await fetchApi(AddWellnessQuiz, "POST",payload);
      if(res.data.message){
        toast.error(res.data.message);
        this.setState({
          buttonStatus: false,
        });
      }else{
        toast.success(res.data[0]);
        this.props.history.push({pathname:"/company/wellness"})
      }
    }catch(error){
      // toast.error(error)
    }
  }

  render() {
    const {
      step,
      title,
      imgSrc,
      description,
      loading,
      date,
      city_location,
      usersToInvite,
      isEmployee,
      isDependent,
      isSpouse,
      departments,
      teams,
      quizTimeValue,
      quizPoint,
      buttonStatus
    } = this.state;
    const { locationDetails, companyDetails  } = this.props;
    if (loading) {
      return <Waiting />;
    }
    return (
      <React.Fragment>
        {step === 1
          ? this.stepOneFormData()
          : step === 2
            ? this.stepTwoForm()
            : step === 3
              ? this.stepThreeFormData()
              : this.stepFourForm()}
        {step === 1 ? (
          <ButtonContainer>
            <Main>
              <CommonButton
                btnType={"squareIcon"}
                onClick={() => this.updateStep(2)}
                title={this.props.t("Next")}
                styles={{color:"#007AB1"}}
                disabled={!title ||!imgSrc ||!description}
                nextIcon={NextArrows("white")}
              />
            </Main>
          </ButtonContainer>
        ) : step === 2 ? (
          <ButtonContainer>
            <div className="wrapper">
              <CommonButton
                btnType={"squareIcon"}
                styles={{color:"white",textColor:"#007AB1",border:"#007AB1",width:"200px", marginLeft:"0px",display:"flex",justifyContent:"center",alignItems:"center" }}
                onClick={() => this.updateStep(1)}
                title={this.props.t("Go Back")}
                icon={Arrows()}
              />
              <CommonButton
                btnType={"squareIcon"} 
                styles={{marginAuto:"auto",color:"#007AB1" }}
                onClick={() => this.updateStep(3)}
                title={this.props.t("Next")}
                nextIcon={NextArrows("white")}
                disabled={(!quizPoint && quizPoint!=0)||!quizTimeValue}
              />
            </div>
          </ButtonContainer>
        ) : step === 3 ? (
          <ButtonContainer>
            <div className="wrapper">
              <CommonButton
                btnType={"squareIcon"}
                styles={{color:"white",textColor:"#007AB1",border:"#007AB1",width:"200px", marginLeft:"0px",display:"flex",justifyContent:"center",alignItems:"center" }}
                onClick={() => this.updateStep(2)}
                title={this.props.t("Go Back")}
                icon={Arrows()}
              />
              <CommonButton
                btnType={"squareIcon"}
                styles={{marginAuto:"auto",color:"#007AB1" }}
                onClick={() => this.updateStep(4)}
                title={this.props.t("Next")}
                nextIcon={NextArrows("white")}
                disabled={
                  ((!city_location.length > 0 &&!_.isEmpty(locationDetails) && usersToInvite === 2) || (!isEmployee && !isDependent&& !isSpouse && _.isEmpty(locationDetails) && companyDetails && companyDetails['spouse_or_dependent'])  || (usersToInvite === 2 && !city_location.length > 0) || (usersToInvite === 1 && !departments.length > 0) || (usersToInvite === 3 && !teams.length > 0))
                }
              />
            </div>
          </ButtonContainer>
        ) : (
          step === 4 && (
            <ButtonContainer>
              <div className="wrapper">
                <CommonButton
                  btnType={"squareIcon"}
                  styles={{color:"white",textColor:"#007AB1",border:"#007AB1",width:"200px", marginLeft:"0px",display:"flex",justifyContent:"center",alignItems:"center"}}
                  onClick={() => this.updateStep(3)}
                  title={this.props.t("Go Back")}
                  icon={Arrows()}
                />
                <CommonButton
                  btnType={"squareIcon"}
                  styles={{color:"#007AB1", marginAuto:'auto'}}
                  onClick={() => this.saveQuiz()}
                  title={this.props.t("Launch Quiz")}
                  nextIcon={LaunchEventIcon()}
                  disabled={!date||buttonStatus}
                />
              </div>
            </ButtonContainer>
          )
        )}
      </React.Fragment>
    );
  }
}

LaunchQuiz.propTypes = {
  history: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  t: PropTypes.func,
  companyInfo: PropTypes.object.isRequired,
  getCompanyDepartment: PropTypes.func,
  getCompanyLocation: PropTypes.func,
  allowChallengeEventForSpouseDependent: PropTypes.func,
  companyDetails: PropTypes.object,
  departmentDetails: PropTypes.array,
  locationDetails: PropTypes.array,
  fetchCompanyForUser: PropTypes.func
};

const mapStateToProps = (state) => ({
  userCompany: state.profileData.userCompany,
  locationDetails: state.register.locationDetails,
  companyDetails: state.profileData.companyDetails,
  departmentDetails: state.register.departmentDetails,
  createEventError:state.events.createEventError
});

const mapDispatchToProps = (dispatch) => ({
  fetchCompanyForUser: () => dispatch(getUserCompanyDetails()),
  allowChallengeEventForSpouseDependent: () =>dispatch(allowChallengeEventForSpouseDependent),
  getCompanyLocation: (id) => dispatch(getCompanyLocation(id)),
  getCompanyDepartment: (id) => dispatch(getCompanyDepartment(id))
});

export default withRouter(connect(mapStateToProps , mapDispatchToProps)(withTranslation()(LaunchQuiz)));